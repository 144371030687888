import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import SubmitButton from './SubmitButtonComponent';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TitleBanner = styled.h2`
  font-family: 'Noto Sans', sans-serif;
  font-weight: 100;
  font-size: 70px;
  color: white;
  text-align: center;
  padding: 1rem 0;
  background-color: #000000;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
    padding: 1rem;
  }
`;

const IntroParagraph = styled.p`
  font-family: 'Noto Sans', sans-serif;
  font-weight: 100;
  font-size: 1.75rem;
  margin: 0 auto;
  color: white;
  text-align: center;
  line-height: 1.8;
  max-width: 100%;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    max-width: 80%;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
    max-width: 90%;
  }
`;

const CardContainer = styled.div`
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px; /* Max width of the container */
  margin: 0 auto;
  background-color: #000000;

  @media (max-width: 1200px) {
    width: 90%; /* Adjust width for smaller screens */
  }

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 1rem;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  align-items: center;
  background-color: #000000;
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s, transform 1s;
  ${({ isVisible }) => isVisible && `
    opacity: 1;
    transform: translateY(0);
  `}

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1.5rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

const CardImageWrapper = styled.div`
  flex: 1;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const CardContent = styled.div`
  flex: 1;
  padding: 1.5rem;

  @media (max-width: 1024px) {
    padding: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const CardTitle = styled.h3`
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.8rem;
  font-weight: 200;
  color: #bbd7ec;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const CardDescription = styled.p`
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.25rem;
  text-align: left;
  font-weight: 100;
  color: white;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const DividerLine = styled.div`
  width: 20%;
  height: 1px;
  background-color: #bbd7ec;
  margin: 3rem auto;
`;

const AboutPageMainContent = () => {
  const [visibleCards, setVisibleCards] = useState([]);
  const cardRefs = useRef([]);

  const cards = [
    { title: "1. Getting to Know Your Business", description: "We begin by understanding your business and brand, ensuring our strategies align with your goals.", image: '/Images/1.png' },
    { title: "2. Competitor Analysis", description: "We analyze competitors and industry trends to develop effective SEO strategies.", image: '/Images/2.png' },
    { title: "3. Wireframe & Sitemap", description: "We create a wireframe and sitemap, integrating your ideas into the design.", image: '/Images/3.png' },
    { title: "4. Prototype Development", description: "We bring your design to life with a functional prototype for review.", image: '/Images/4.png' },
    { title: "5. Site Launch & Testing", description: "We transition your site to the live server and conduct extensive testing.", image: '/Images/5.png' },
    { title: "6. Ongoing Optimization", description: "After launch, we continuously monitor and optimize your site for performance.", image: '/Images/6.png' },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleCards((prevVisible) => [...prevVisible, entry.target]);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);

  return (
    <>
      <TitleBanner>Our Process</TitleBanner>
      <IntroParagraph>
        Our approach is grounded in understanding your vision and aligning strategies to meet your goals.
      </IntroParagraph>
      <CardContainer>
        {cards.map((card, index) => (
          <React.Fragment key={index}>
            <Card
              reversed={index % 2 !== 0}
              isVisible={visibleCards.includes(cardRefs.current[index])}
              ref={(el) => (cardRefs.current[index] = el)}
            >
              <CardImageWrapper>
                <CardImage src={process.env.PUBLIC_URL + card.image} alt={card.title} />
              </CardImageWrapper>
              <CardContent>
                <CardTitle>{card.title}</CardTitle>
                <CardDescription>{card.description}</CardDescription>
              </CardContent>
            </Card>
            {index < cards.length - 1 && <DividerLine />}
          </React.Fragment>
        ))}
      </CardContainer>
      <IntroParagraph>
        Ready to bring your ideas to life? Let's get started!
      </IntroParagraph>
      <div style={{ padding: '5rem' }}>
        <SubmitButton to="/consultation" text="Book a Free Consultation" />
      </div>
    </>
  );
};

export default AboutPageMainContent;