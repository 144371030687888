import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './MobileHeader.module.css';

const logo = `${process.env.PUBLIC_URL}/Images/logo13.png`;

const MobileHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle closing the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={styles.mobileHeader}>
      <div className={styles.logoContainer} onClick={toggleDropdown}>
        <img className={styles.logo} src={logo} alt="BuildMe Logo" />
      </div>

      <div
        ref={dropdownRef}
        className={`${styles.overlay} ${isDropdownOpen ? styles.open : ''}`}
      >
        <ul className={styles.mobileMenu}>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/" onClick={toggleDropdown}>Home</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/about" onClick={toggleDropdown}>About</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/blog" onClick={toggleDropdown}>Blog</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/consultation" onClick={toggleDropdown}>Free Consultation</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default MobileHeader;