import React from 'react';
import styled from 'styled-components';

// Styled Components for Layout
const Section = styled.section`
  width: 100%;
  display: flex;
  min-height: 20vh;
  justify-content: center;
  
    padding: 3rem 2rem;


  background-color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  padding-right: 100px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1.25;
  padding-bottom: 1.25rem;
  font-family: 'Noto Sans', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.6;
  font-family: 'Noto Sans', sans-serif;
  padding-bottom: 1rem;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.75rem;
`;

const Icon = styled.span`
  display: block;
  font-size: 4rem;
  margin-right: 55px;
`;

const BenefitText = styled.p`
  font-size: 1.5rem;
  font-family: 'Noto Sans', sans-serif;
`;

// Main Component
const WebBenefitsSection = () => {
  return (
    <Section>
      <LeftContainer>
        <Heading>Your website is your brand's most visible and valuable salesperson.</Heading>
        <Paragraph>
          Visitors judge whether or not they trust your business from the moment they land on your site.
        </Paragraph>
        <Paragraph>
          Design, copy, UX, mobile performance, and speed are a lot to juggle – but your customer’s confidence
          in you depends on it.
        </Paragraph>
        <Paragraph>
          We transform your website into a powerful, professional communication tool that reflects the premium
          quality of work you do.
        </Paragraph>
      </LeftContainer>

      <RightContainer>
        <BenefitItem>
          <Icon>🌐</Icon>
          <BenefitText>Deliver a Beautiful User Experience</BenefitText>
        </BenefitItem>

        <BenefitItem>
          <Icon>🌊</Icon>
          <BenefitText>Enjoy Smoother Operations</BenefitText>
        </BenefitItem>

        <BenefitItem>
          <Icon>📈</Icon>
          <BenefitText>Drive More Online Leads & Purchases</BenefitText>
        </BenefitItem>
      </RightContainer>
    </Section>
  );
};

export default WebBenefitsSection;