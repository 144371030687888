import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import MobileHeader from './MobileHeader';

const logo = `${process.env.PUBLIC_URL}/Images/logo13.png`;

const NavigationMenuBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [showHeader, setShowHeader] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  if (isMobile) {
    return <MobileHeader />;
  }

  return (
    <header className={`${styles.header} ${showHeader ? styles.headerShow : styles.headerHide}`}>
      <nav className={styles.nav}>
        {/* Left Side - Logo */}
        <div className={styles.brand}>
          <img className={styles.logo} src={logo} alt="BuildMe Logo" />
        </div>

        {/* Middle - Navigation Links (Absolutely centered) */}
        <ul className={styles.navMenu}>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/">Home</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/about">About</Link>
          </li>
          <li className={styles.menuItem}>
            <Link className={styles.styledLink} to="/blog">Blog</Link>
          </li>
          <li className={styles.menuItem} onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <div className={styles.styledLink}>
              Services <span className={styles.downArrow}>▼</span>
            </div>
            {dropdownOpen && (
              <ul className={styles.dropdown}>
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/services/web-development">Websites</Link></li>
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/services/search-engine-optimization">SEO</Link></li>
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/services/search-engine-marketing">Search Marketing</Link></li>
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/services/dns-migration">Domains</Link></li>
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/services/web-hosting">Hosting</Link></li>


                
                
                
                <li className={styles.dropdownItem}><Link className={styles.dropdownLink} to="/casestudies">Case Studies</Link></li>

              </ul>
            )}
          </li>
        </ul>

        {/* Right Side - Free Consultation Button */}
        <Link to="/consultation">
          <button className={styles.consultationButton}>Free Consultation</button>
        </Link>
      </nav>
    </header>
  );
};

export default NavigationMenuBar;