import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

import Header from './components/Header';

import AboutPage from './pages/AboutPage';
import ScrollToTop from './components/ScrollToTop';
import HomePage from './pages/Home';
import BlogPage from './pages/Blog';
import ServicesPage from './pages/Services';
import ConsultationPage from './pages/Consultation';
import './global.css';
import BlogPost1 from './components/BlogPost1';
import BlogPost2 from './components/BlogPost2';
import BlogPost3 from './components/BlogPost3';
import BlogPost4 from './components/BlogPost4';
import BlogPost5 from './components/BlogPost5';
import BlogPost6 from './components/BlogPost6';


import Footer3 from './components/Footer3';
import SeoPage from './pages/ServicesPages/SEOPage';
import WebHostingPage from './pages/ServicesPages/WebHostingPage';
import SEMPage from './pages/ServicesPages/SEMPage';
import DNSMigrationPage from './pages/ServicesPages/DNSMigrationPage';
import WebDevPage from './pages/ServicesPages/WebDevPage';
import WebDesignPage from './pages/ServicesPages/WebDesignPage';
import Jackie from './pages/ServicesPages/Jackie';


// Initialize Google Analytics
ReactGA.initialize('G-YYTCRX2PFK');

function App() {
  // Track page views
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Build Me A Website - Professional Web Development Services</title>
          <meta name="description" content="Build Me A Website offers professional and affordable web development services tailored to your business needs. Get in touch with us today!" />
          <meta name="keywords" content="web development, website design, web hosting, DNS management, SEO services, SEM strategies, digital marketing, responsive design, ecommerce solutions, UX/UI design, content management systems, website optimization, online branding, website maintenance, professional web services, web security, custom websites, domain registration, web analytics" />

          <meta property="og:title" content="Build Me A Website - Professional Web Development Services" />
          <meta property="og:description" content="Build Me A Website offers professional and affordable web development services tailored to your business needs. Get in touch with us today!" />
          <meta property="og:url" content="https://buildmeawebsite.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://buildmeawebsite.com/og-image.jpg" />
          <meta name="robots" content="index, follow" />
          <link rel="preload" href="/assets/styles/main.css" as="style" />
          <link rel="stylesheet" href="https://buildmeawebsite.com/assets/styles/main.css" />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-YYTCRX2PFK');
            `}
          </script>
        </Helmet>

        <Header />
        <ScrollToTop />

        <div style={{ background: '#000000' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/consultation" element={<ConsultationPage />} />
            <Route path="/" element={<ConsultationPage />} />
           
      
      



                     <Route path="/blog/css" element={<BlogPost1 />} />
                     <Route path="/blog/why-react" element={<BlogPost2 />} />
                     <Route path="/blog/responsiveness-in-web-development" element={<BlogPost3 />} />
                     <Route path="/blog/mastering-css-grid-flexbox" element={<BlogPost4 />} />
                     <Route path="/blog/boosting-website-speed-seo" element={<BlogPost5 />} />
                     <Route path="/blog/web-accessibility-inclusive-design" element={<BlogPost6 />} />



                     <Route path="/services/web-hosting" element={<WebHostingPage />} />
                     <Route path="/services/search-engine-optimization" element={<SeoPage />} />

                     <Route path="/services/search-engine-marketing" element={<SEMPage />} />
                     <Route path="/services/dns-migration" element={<DNSMigrationPage />} />
                     <Route path="/services/web-development" element={<WebDevPage />} />
                     <Route path="/services/web-design" element={<WebDesignPage />} />
                     <Route path="/jackie" element={<Jackie />} />

         
          </Routes>
        </div>

        <Footer3 />
      </Router>
    </HelmetProvider>
  );
}

export default App;
