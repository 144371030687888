import React from 'react';
import styles from './PricingTable.module.css';

const PricingTable = () => {
  return (
    <div
    style = {{margin:"1rem auto", padding:'5rem 0'}}
    >
      <div className={styles.pricing}>
        {/* Basic Website Package */}
        <div className={`${styles.plan} ${styles.starter}`}>
          <span className={styles.planLabel}>Basic</span> {/* Customizable Label */}
          <div className={styles.price}>CAD 199</div>
          <ul className={styles.features}>
            <li><i className="fas fa-check-circle"></i>Custom website design (up to 5 pages)</li>
            <li><i className="fas fa-check-circle"></i>Responsive design optimized for mobile and desktop</li>
            <li><i className="fas fa-check-circle"></i>Basic SEO setup (meta tags, keywords, and descriptions)</li>
            <li><i className="fas fa-check-circle"></i>Integration of social media links</li>
            <li><i className="fas fa-check-circle"></i>Basic hosting setup</li>
            <li><i className="fas fa-check-circle"></i>Optional Monthly SEO Management: CAD 9.99/mo</li>
          </ul>
        </div>
        
        {/* Enhanced */}
        <div className={`${styles.plan} ${styles.popular}`}>
          <span className={styles.planLabel}>Enhanced</span> {/* Customizable Label */}
          <div className={styles.price}>CAD 399</div>
          <ul className={styles.features}>
            <li><i className="fas fa-check-circle"></i>Custom website design (up to 5 pages)</li>
            <li><i className="fas fa-check-circle"></i>Advanced SEO setup (meta tags, keyword research, and on-page optimization)</li>
            <li><i className="fas fa-check-circle"></i>Blog setup or portfolio section</li>
            <li><i className="fas fa-check-circle"></i>Integration of social media links and email capture forms</li>
            <li><i className="fas fa-check-circle"></i>Hosting setup with continuous monitoring</li>
            <li><i className="fas fa-check-circle"></i>Optional Monthly SEO Management: CAD 14.99/mo</li>
          </ul>
      
        </div>
        
        {/* Complete */}
        <div className={`${styles.plan} ${styles.value}`}>
          <span className={styles.planLabel}>Complete</span> {/* Customizable Label */}
          <div className={styles.price}>CAD 799</div>
          <ul className={styles.features}>
            <li><i className="fas fa-check-circle"></i>Custom website design (up to 10 pages)</li>
            <li><i className="fas fa-check-circle"></i>Full e-commerce functionality (up to 10 products)</li>
            <li><i className="fas fa-check-circle"></i>Comprehensive SEO strategy (including competitor analysis)</li>
            <li><i className="fas fa-check-circle"></i>Integration of email marketing tools and social media platforms</li>
            <li><i className="fas fa-check-circle"></i>Hosting setup with continuous monitoring and priority support</li>
            <li><i className="fas fa-check-circle"></i>Monthly SEO reporting and regular updates</li>
            <li><i className="fas fa-check-circle"></i><strong>30-Day Money-Back Guarantee</strong></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;