import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  padding-top: 200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end; /* Align items to the right */
  height: 50vh;
  padding: 2rem;
  text-align: right; /* Align text to the right */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SmallText = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const BigText = styled.h1`
  font-family: 'Nunito', sans-serif;
  font-size: 8rem;
  font-weight: 200;
  margin: 0.5rem 0;
  color: #bbd7ec; /* Set BigText color to #bbd7ec */

  @media (max-width: 768px) {
    font-size: 6rem;
  }

  @media (max-width: 480px) {
    font-size: 4rem;
  }
`;

const FooterText = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  color: #999999;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items within FooterText to the right */

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const GoogleLogo = styled.img`
  width: 50px;
  margin-left: 0.5rem;
`;

const StatComponent = () => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the animation on page load
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Small delay to allow the transition effect

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const target = 300;
      const increment = 1;
      const duration = 2000;
      const intervalTime = Math.floor(duration / (target / increment));

      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < target) {
            return prevCount + increment;
          } else {
            clearInterval(interval);
            return target;
          }
        });
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    <Container id="stat-component" className={isVisible ? 'visible' : ''}>
      <SmallText>Businesses that optimize their web presence see</SmallText>
      <BigText>{count}%</BigText>
      <SmallText>increase in visibility and customer engagement within the first year.</SmallText>
      <FooterText>
        Source: <strong>Google Economic Impact Report</strong>
        <GoogleLogo src={`${process.env.PUBLIC_URL}/Images/google.png`} alt="Google Logo" />
      </FooterText>
    </Container>
  );
};

export default StatComponent;